<template>
    <main class="flex-shrink-0">
        <div class="container mb-5">
            <div class="row justify-content-md-center" style="height: 100%;">
                <div class="col-md-auto">
                    <div class="div-center">
                        <div v-if="loading">
                            <div class="mb-3" style="width: 100%;float: left;"> <h5 class="h5-custom-modal" v-html="message"></h5> </div>
                            <div style="text-align: center;">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" v-bind:svg-inline="''" v-bind:width="'60'" v-bind:class="'refresh-animated'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="25" cy="25" r="21.2" fill="none" stroke="#0073e6" stroke-width="2" stroke-miterlimit="10"/><circle cx="25" cy="4.6" r="4.5" fill="#4282b3"/></svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <!-- Component ModalAlert -->
    <modal-alert v-if="modalAlertShow" :type="'Error'" :alertTitle="$t('labelFailedToNormalize')" :alertMessage="$t('labelTheFileMayBeUnreadableOrHaveAnError')" :okLabel="$t('labelClose')" @close="closeModal" />
</template>

<script>
    import ModalAlert from '@/components/common/modal-alert';
    import api from "@/services/api";

    export default {
        name: "NormalizeIndex",
        data() {
            return {
                idAnalyzer: this.$route.params.id,
                backPage: this.$route.query.page,
                title: "Normalize Index",
                loading: true,
                message: "",
                modalAlertShow: false,
                myInterval: null,
                timeoutMessage: ENV_CONFIG.VUE_APP_WAITING_TIME_MSG_UPLD,
            }
        },
        components: {
            ModalAlert,
        },
        watch: {},
        methods: {
            verifyNormalizedDoc: function () {
                let self = this;
                api.get('/Inquiry/Status/' + this.idAnalyzer)
                    .then(function (response) { // Handle success
                        if (response.data.status === 0) { // Status not analyzed
                            self.message = self.$t('labelNormalizingTheDocument');
                            self.normalizeDoc();
                        } else {
                            self.message = self.$t('labelDocumentHasAlreadyBeenStandardizedPreviously', [response.data.nrIdea]);
                            self.redirectToDocument();
                        }
                    }).catch(function (e) { // Handle error
                        console.log(e);
                    }).finally(function () { // Always executed
                        console.log("Finished request.");
                    });
            },
            normalizeDoc: function () {
                window.onbeforeunload = function () { return true; };
                let self = this;
                api.post('/Inquiry/Analyze/' + this.idAnalyzer)
                    .then(function (response) { // Handle success
                        window.onbeforeunload = null;
                        self.redirectToAnalyzer();
                    }).catch(function (e) { // Handle error
                        window.onbeforeunload = null;
                        console.log(e);
                        self.loading = false;
                        self.showModal();
                    }).finally(function () { // Always executed
                        console.log("Finished request.");
                    });
            },
            redirectToAnalyzer: function () {
                let self = this;
                setTimeout(function () {
                    self.$router.push({ name: 'Analyzer', params: { id: self.idAnalyzer }, query: { page: self.backPage } });
                }, 500);
            },
            redirectToDocument: function () {
                let self = this;
                setTimeout(function () {
                    self.$router.push({ name: 'DocumentList', query: { page: self.backPage } });
                }, 6000);
            },
            showModal: function () {
                this.modalAlertShow = true;
                document.getElementsByTagName("BODY")[0].children[1].className += " active";
            },
            closeModal: function () {
                this.modalAlertShow = false;
                document.getElementsByTagName("BODY")[0].children[1].className = "overlay";
                let self = this;
                setTimeout(function () {
                    self.$router.push({ name: 'DocumentList', query: { page: self.backPage } });
                }, 10);
            },
        },
        computed: {},
        created() {
            this.message = this.$t('labelPreparingTheDocument');
            this.verifyNormalizedDoc();
        },
        mounted() { },
        unmounted() { },
    }
</script>

<style scoped>
    .div-center {
        position: relative;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        /*width: 700px;*/
    }

    .h5-custom-modal {
        font-weight: initial;
        color: #0073E6;
        text-align: center;
    }

    /* Refresh animated  */
    .refresh-animated {
        -webkit-animation: spin 2s linear infinite;
        -moz-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }

    @-moz-keyframes spin {
        100% {
            -moz-transform: rotate(360deg);
        }
    }

    @-webkit-keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
</style>
